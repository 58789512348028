import './style.css'
import * as dat from 'lil-gui'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import typefaceFont from 'three/examples/fonts/helvetiker_regular.typeface.json'
import { Mesh, ReinhardToneMapping } from 'three'

/**
 * Base
 */
// Debug
const gui = new dat.GUI({
    width: 400
})

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

const params = {color: '#d3d3d3'}
scene.background = new THREE.Color( params.color );

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()

// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

//Update all Materials with EnvMap, Shadows,...
const updateAllMaterials = () =>{
    scene.traverse((child) =>{
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            //console.log(child)
            child.material.envMap = environmentMapTexture
            child.material.envMapIntensity = 2
            child.castShadow = true
            child.receiveShadow = true
        }

    })
}

//Enviroment
const environmentMapTexture = cubeTextureLoader.load([
    '/environmentMaps/1/px.jpg',
    '/environmentMaps/1/nx.jpg',
    '/environmentMaps/1/py.jpg',
    '/environmentMaps/1/ny.jpg',
    '/environmentMaps/1/pz.jpg',
    '/environmentMaps/1/nz.jpg'
])
//Show enviromental Map in BG
//scene.background = environmentMapTexture
//scene.environment = environmentMapTexture

/**
 * Textures
 */
 const Color_Texture = textureLoader.load('bake_Color.png')
 const Ambient_Texture = textureLoader.load('bake_Ambient.png')
 const Normal_Texture = textureLoader.load('bake_Normal.png')
 const Reflection_Texture = textureLoader.load('bake_Reflection.png')
 const Bump_Texture = textureLoader.load('bake_Bump.png')
 const Displacement_Texture = textureLoader.load('bake_Displacement.png')

/**
 * Materials
 */
//Baked Materials
const material = new THREE.MeshBasicMaterial()
material.map = Color_Texture

material.metalnessMap = Reflection_Texture
material.metalness = 0
//gui.add(material, 'metalness').min(0).max(1).step(0.0001)

material.roughnessMap = Reflection_Texture
material.roughness = 1
//gui.add(material, 'roughness').min(0).max(1).step(0.0001)

material.normalMap = Normal_Texture

material.aoMap = Ambient_Texture
material.aoMapIntensity = 1
//gui.add(material, 'aoMapIntensity').min(0).max(1).step(0.0001)

material.displacementMap = Displacement_Texture
material.displacementScale = 0.05
//gui.add(material, 'displacementScale').min(0).max(1).step(0.0001)

//enviromental Map
const material_Env = new THREE.MeshStandardMaterial()
material_Env.envMap = environmentMapTexture

///////////////////////////////////////////////////////////////////////////////////////
/**
 * Object
 */

let mixer = null
var este

gltfLoader.load(
    './este_metal.glb',
    (gltf) =>
    {
        este = gltf.scene
        este.traverse((child) => {
            

        })
            

        gltf.scene.scale.set(0.1, 0.1, 0.1)
        gltf.scene.rotation.set(0, 0, 0)

        scene.add(este)

                // Animation
                mixer = new THREE.AnimationMixer(este)
                //const action = mixer.clipAction(gltf.animations[1])
                //action.play()
        
                updateAllMaterials()
    }
)
///////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 25
camera.position.y = 20
camera.position.z = 25
scene.add(camera)

/**
 * Lights
 */
/*
 const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
 scene.add(ambientLight)
 */
 const directionalLight = new THREE.DirectionalLight(0xffffff, 0.3)
 directionalLight.castShadow = true
 directionalLight.shadow.camera.far = 15
 directionalLight.shadow.mapSize.set(1024, 1024)
 directionalLight.shadow.camera.far = 15
 directionalLight.shadow.camera.left = - 7
 directionalLight.shadow.camera.top = 7
 directionalLight.shadow.camera.right = 7
 directionalLight.shadow.camera.bottom = - 7
 directionalLight.position.set(- 5, 5, 0)
 scene.add(directionalLight)

// const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
 //scene.add(directionalLightCameraHelper)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap

gui.add(renderer, 'toneMapping',{
    No: THREE.NoToneMapping,
    Linear: THREE.LinearToneMapping,
    Reinhard: THREE.ReinhardToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping
})
.onFinishChange(() =>{
    renderer.toneMapping = Number(renderer.toneMapping)
    updateAllMaterials()
})

gui.add(renderer, 'toneMappingExposure').min(0).max(2).step(0.001)

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Model animation
    if(mixer)
    {
       // model.rotation.x += 0.05
        este.rotation.y += -0.0001
        este.rotation.z += -0.0001
        mixer.update(deltaTime)
    }

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()